.container_tiles {
    margin: 50px;
    width: 1251px;
    /* -webkit-transform: scale(0.9); */
    display: contents;
}

.wideNonLive {
    width: 259px;
    height: 124px;
    overflow: hidden;
    font-size: 13px;
    padding: 10px;
    display: block;
    float: left;
    margin: 5px;
}

.careerPost {
    color: hsla(0, 0%, 100%, .6);
}

.projectPost {
    color: hsla(0, 0%, 100%, .8);
}

.darkBlue {
    background-color: rgb(65, 105, 225);
}

.box {
    width: 124px;
    height: 124px;
    overflow: hidden;
    font-size: 13px;
    padding: 10px;
    display: block;
    float: left;
    /* margin: 5px; */
    margin: 0px 5px 5px 5px;
}

.purple{
    background-color: rgb(148, 0, 211);;
}
.black{
    background: black;
}

.lime {
    background: #61b812;
}

.orange {
    background: #e76022;
}

.blue {
    background: #1E90FF;
}

.redgay {
    background: #DA312E;
}

.yellow {
    background: #dbb701;
}

.bluefish {
    background: #02b9e3;
}

.magenta {
    background: #d22a4e;
}

.spacer {
    width: 770px;
    margin: auto;
}

.spacer3x {
    width: 300px;
}

@-webkit-keyframes galeri {
    1% {
        background-position: 0px 0px;
    }

    10% {
        background-position: 0px 140px;
    }

    20% {
        background-position: 0px 140px;
    }

    30% {
        background-position: 0px 280px;
    }

    40% {
        background-position: 0px 280px;
    }

    50% {
        background-position: 0px 420px;
    }

    60% {
        background-position: 0px 420px;
    }

    70% {
        background-position: 0px 560px;
    }

    80% {
        background-position: 0px 560px;
    }

    100% {
        background-position: 0px 560px;
    }
}

.gallery {
    background: url(https://dl.dropbox.com/u/39272011/image_metro.png);
    -webkit-animation: galeri 15s infinite;
}

.right {
    float: right;
    text-align: right;
    margin-top: 30px;
}

    .right p {
        margin: 5px 0px;
    }

img.prof {
    float: right;
    margin: 10px;
    margin-top: 40px;
}

.i_bot {
    font-size: 30px;
    text-align: left;
    position: absolute;
    bottom: 0px;
}

.cal_i {
    margin-top: 20px;
    margin-left: 15px;
    width: 155px;
}

.cal_e h1 {
    position: absolute;
    right: 0px;
    width: 115px;
    text-align: center;
    margin: 0px;
    font-size: 60px;
}

.cal_e p {
    position: absolute;
    right: 0px;
    width: 115px;
    text-align: center;
    margin-top: 75px;
    text-transform: uppercase;
}

.cal_e i {
    text-align: left;
    font-size: 25px !important;
    position: absolute;
    bottom: 0px;
}

/** For live tiles*/

.LiveTilesWrapper {
    width: 280px;
    height: 130px;
    overflow: hidden;
    /* position:absolute; */
} 

.LiveTile {
    width: 254px;
    height: 124px;
    position: relative;
    /*perspective: 600px;*/
    transform-style: preserve-3d;
    /*transform: rotateY(180deg);*/
    transition: transform 0.5s ease 0s;
    margin:auto;
}
.liveCube-face {
    width: inherit;
    height: inherit;
    position: absolute;
}

.wideNonLive.liveCube-face{
    margin:auto;
    position:absolute;
}

/* .liveCube-face.faceFront {
    transform: translate3d(0, 0, 62px);
}

    .liveCube-face.faceTop {
        transform: rotateX(90deg) translate3d(0px, 0px, 62px);
    }

    .liveCube-face.faceBack {
        transform: rotateY(180deg) translate3d(0px, 0px, 62px);
    }

    .liveCube-face.faceBottom {
        transform: rotateX(-90deg) translate3d(0px, 0px, 62px);
    }

.faceSideLeft {
    width: 124px;
    height: 124px;
    transform: rotateY(-90deg) translate3d(0px, 0px, 62px);
}

.faceSideRight {
    width: 124px;
    height: 124px;
    background: magenta;
    transform: rotateY(90deg) translate3d(0px, 0px, 62px);
}  */

