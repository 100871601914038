.page-header {
  padding-left: 20px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;

  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, .15);
  backdrop-filter: blur(5px);
}

.page-header nav {
  display: block;
}

.page-header nav a {
  margin-left: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  font-family: Montserrat, tahoma, verdana, sans-serif;

  &:first-child {
    margin-left: 0;
  }
}

.page-header__wrapper {
  
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-right: 70px;
  padding-top: 30px;
  // padding: 30px 10%;
  align-items: center;

  @media all and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 24px;

    nav {
      margin-top: 24px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      a {
        background-color: rgba(0, 0, 0, 0.05);
        margin-left: 10px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .page-header__title h1 {
      font-size: 20px;
      line-height: 28px;
    }

    .logo-container {
      --size: 64px;
    }
  }
  @media all and (max-width: 475px) {
    padding: 20px;

    .page-header__title h1 {
      font-size: 18px;
      line-height: 24px;
    }
    .page-header__title h2 {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.page-header small {
  font-size: 8px;
  text-transform: uppercase;
  font-family: Montserrat, tahoma, verdana, sans-serif;
  color: #fff;
}

.page-header small a {
  color: #fff;
  font-weight: bold;
}

.page-header__wrapper > :nth-child(1),
.page-header__wrapper > :nth-child(2) {
//   transform: translateY(-175px);
}

.animated .page-header__wrapper > :nth-child(1),
.animated .page-header__wrapper > :nth-child(2) {
  transition: transform 335ms cubic-bezier(0.05, 0.55, 0.15, 1);
}

.animated .page-header__wrapper > :nth-child(2) {
  transition-delay: 0.125s;
}

.page-header__title {
  display: flex;
  align-items: center;
}

.page-header__title h1 {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-family: Montserrat, tahoma, verdana, sans-serif;
  display: block;
}

.page-header__title h2 {
  color: #fff;
  font-family: Montserrat, tahoma, verdana, sans-serif;
  display: block;
  font-size: 14px;
  display: block;
}

.page-header__title > div {
  margin-left: 8px;
}

.page-header__title h1 span {
  color: #fff;
  font-family: Montserrat, tahoma, verdana, sans-serif;
  display: inline-block;
}
.page-header__title h1 span:first-child {
  margin-right: 10px;
  color: #61dafb;
}

.page-header a.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.page-header a:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.visible .page-header__wrapper > :nth-child(1),
.visible .page-header__wrapper > :nth-child(2) {
  transform: translateY(0);
}

.MenuBox {
    width: 8px;
    height: 8px;
    background-color: white;
    position: relative;
    margin: 2px;

    


    &.long {
        width: 20px;
    }

    &.bigBox {
        height: 20px;
        width: 20px
    }
}



.menu__wrapper {
    // width: 160px;

    @media all and (min-width: 800px){
      .page-header_name{
        color:white;
        &:hover {
          text-decoration: none;
        }
      }
    }

    @media all and (max-width: 800px){
      .page-header_name{
        display: none;
      }
    }
    

    .menu__item--bento {
      &:hover {
        animation: shake 0.5s;
        animation-iteration-count: infinite
    }
        .row {
            .container {
                margin: 0px;
                padding: 0px;
                width: 30px;

                .row {
                    margin-left: 0px
                }
            }
        }
    }
}

.darkBlue {
    background-color: rgb(65, 105, 225);
}
.purple {
    background-color: rgb(148, 0, 211);
}

.black {
    background: black;
}

.lime {
    background: #61b812;
}

.orange {
    background: #e76022;
}

.blue {
    background: #1E90FF;
}

.redgay {
    background: #DA312E;
}

.yellow {
    background: #dbb701;
}

.bluefish {
    background: #02b9e3;
}

.magenta {
    background: #d22a4e;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}