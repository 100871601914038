.lettering {
  padding: 0px 20px;
  color: #fff;
  font-family: monospace;
  text-align: center;
  position: relative;
  line-height: 1.5;
}

.lettering h1 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}

.lettering p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.lettering.Home {
  max-width: 900px;
}

.lettering.Home .sentence.Home {
  padding-bottom: 10px;
}
