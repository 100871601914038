.section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  position: relative;
}

.awssld--fill-parent {
  height: 100vh !important;
}

.awssld__content .section {
  /* overflow: hidden scroll; */
  background-color: rgb(69, 74, 88);
  /*background-color: #56769a;*/
}

.container_tiles .container {
  margin-bottom: 0%;
}

.container {
  margin-bottom: 30%;
}

/*neo morphism*/
/* .gallery_product{
      margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    position: absolute;
    left: 100px;
    top: 0px;
} */

.h_gallery_item.projectItems:hover {
  transform: scale(0.95);
}

.h_gallery_item {
  /* margin-left: auto;
    margin-right: auto; */
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  transform: scale(0.9);
  height: 288px;
  width: 324px;

  display: inline-block;
  /* margin-bottom: 10px; */
  /* border: 4px #668cb7 double; */
  background: #404a58;
  box-shadow: 5px 5px 11px #333b46, -5px -5px 11px #4d596a;
  /* box-shadow: 1px 1px 10px #323e4a; */
  /* background: linear-gradient(145deg, #56769a, #668cb7);
    box-shadow:  5px 5px 12px #557598, 
             -5px -5px 12px #6991be;

             transition: top ease 0.5s;*/
}

.h_gallery_item .g_img_item {
  padding: 10px;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.img_class {
  height: 240px;
  /*width: 310px;*/
  border-color: #e0e5ec;
  border-style: solid;
  border-width: thin;
}

.label {
  display: inline-block;
  padding: 3px;
  /* background: #575858; */
  background: #6b6b6b;
  color: #ccc;
  margin-right: 4px;
  border-radius: 5px;
  font-size: small;
  font-weight: bold;
  margin-bottom: 0px;
}
