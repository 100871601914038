@media all and (min-width: 700px) {
    .researchBody {
        margin-top: 70px;
    }
}

@media all and (max-width: 800px) {
    .researchBody {
        margin-top: 80px;
    }
}

@media all and (max-width: 500px) {
    .researchBody {
        margin-top: 100px;
    }
}

.research .h_gallery_item,
.education .h_gallery_item {
    margin-left: auto;
    margin-right: auto;

    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    width: 100%;
    display: inline-block;
    margin-bottom: 45px;
    height: fit-content;

    background: #404a58;
    box-shadow: 5px 5px 11px #333b46, -5px -5px 11px #4d596a;
}

.research .rowElement,
.education .rowElement {
    width: 100%;
    height: fit-content;
}

.work-left,
.work-right {
    display: table-cell;
    vertical-align: top;
}

.work-left {
    width: 95px;
    text-align: center;
    position: relative;
}

.work-icon {
    position: relative;
    display: block;
    height: 50px;
    width: 50px;
}

.has-bursted {
    animation: stary-burst 1.8s 1;
    animation-fill-mode: forwards;
    background-attachment: scroll;
    animation-delay: 1s;
}

.work-date {
    margin: -7px 0 10px;
    font-size: 90%;
    font-weight: 800;
    display: block;
    width: 100%;
}

.work-title {
    display: inline-block;
    margin: 0 0 -1px -10px;
    margin-right: -10px;
    padding: 0 10px;
    line-height: 1.333em;
    border-radius: 4px;
    font-size: 82%;
    font-weight: 700;
}

.item-icon-base {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    margin-top: 30%;
    margin-left: 30%;
    opacity: 0;
}

@keyframes stary-burst {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.logo {
    width: 80%;
    height: 80%;
}

.game-lettering b.cursor {
    position: absolute;
    animation: cursorAnimation 0.5s forwards infinite;
}
