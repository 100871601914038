@import "~bootstrap/scss/bootstrap";
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media all and (max-width: 530px) {
    .content__main {
      margin-top:-10%;
    }
  }
  @media all and (max-width: 768px) {
    .content__main {
      padding: 0 12%;
    }

    .content__action {
      flex-direction: column;
      align-items: center;
      margin-bottom: -20px;

      > div:nth-child(1) {
        margin-bottom: 16px;
      }
      > div:nth-child(2) {
        margin-bottom: 16px;
      }

    }
  }
}


.content__main {
  margin-top:10%;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.content__action {

  margin-bottom: 56px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.content__action {
  display: flex;
  justify-content: space-around;
  padding: 24px 10%;

  > div {
    flex: 1;
    // text-align: center;
  }
  > div:nth-child(1) {
    text-align: right;
  }
  > div:nth-child(2) {
    text-align: right;
  }
  > div:nth-child(3) {
    // text-align: right;
  }
  > div:nth-child(4) {
    text-align: left;
  }
}

.content__action__go {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content__action__github {
  a.gitHubCont {
    font-family: Montserrat, tahoma, verdana, sans-serif;
    color: rgb(199, 198, 198);
    display: inline-block;
    padding: 10px 16px;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    background-color: rgb(48, 39, 39);
    
  }
  a:hover{
    color:rgb(148, 128, 128);
    text-decoration: none;
  }
  svg.gitHubCont {
    width: 20px;
    vertical-align: text-bottom;
    margin-bottom: -2px;
    margin-right: 6px;
  }

  svg.LICont {
    background-color: #312727;
    padding: 6px;
    height: 40px;
    width: 162px;
    vertical-align: text-bottom;
    margin-bottom: -10px;
    margin-right: 6px;
  }
}

.content__action__select {
  select {
    width: 180px;
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    color: #fff;
    font-family: Montserrat, tahoma, verdana, sans-serif;
    font-size: 12px;
    font-weight: bold;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9ItCh0LvQvtC5XzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEzOSAxMzk7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMzkgMTM5IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkY7c3Ryb2tlLXdpZHRoOjY7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQo8L3N0eWxlPjxsaW5lIGNsYXNzPSJzdDAiIGlkPSJYTUxJRF83XyIgeDE9IjE4IiB4Mj0iMTIxIiB5MT0iMzQuOCIgeTI9IjM0LjgiLz48bGluZSBjbGFzcz0ic3QwIiBpZD0iWE1MSURfOF8iIHgxPSI5NS4yIiB4Mj0iMTgiIHkxPSI2OS41IiB5Mj0iNjkuNSIvPjxsaW5lIGNsYXNzPSJzdDAiIGlkPSJYTUxJRF85XyIgeDE9IjY5LjUiIHgyPSIxOCIgeTE9IjEwNC4yIiB5Mj0iMTA0LjIiLz48L3N2Zz4=");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 50%;
  }
}

.awssld__controls__arrow-left:before{
  background-color: aliceblue;
}

.awssld__controls__arrow-left:after{
  background-color: aliceblue;
}

.awssld__controls__arrow-right:before{
  background-color: aliceblue;
}

.awssld__controls__arrow-right:after{
  background-color: aliceblue;
}

.aws-sld {
  &__content {
    p {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1), opacity 0.35s ease-out;
    }
    p:nth-child(2) {
      transition-delay: 0.05s, 0.05s;
    }
    &--exit {
      p {
        transition: transform 0.225s cubic-bezier(0.85, 0, 0.85, 0.7), opacity 0.4s ease-out;
      }
      p:nth-child(2) {
        transition-delay: 0.05s, 0.05s;
      }
    }
    &--moveLeft {
      p {
        transform: translate3d(-50px, 0, 0);
        opacity: 0;
      }
    }
    &--moveRight {
      p {
        transform: translate3d(50px, 0, 0);
        opacity: 0;
      }
    }
  }
}

@media all and (min-width: 560px) {
  .hand-icon{
    display: none;
  }
}
.icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  bottom: 10%;
}

@media all and (max-width: 560px) {
  .mouse-icon {
    display: none;
  }
}



//
// Mouse
// --------------------------------------
.mouse-icon {
  position: relative;
  
  .mouse {
    width: 18px;
    height: 28px;
    background: #fff;
    border-radius: 20px;
    position: relative;
    margin-bottom: 3px;

    &:after {
      content: "";
      background: rgb(41, 45, 58);;
      width: 4px;
      height: 6px;
      position: absolute;
      top: 5px;
      left: 50%;
      margin-left: -2px;
      border-radius: 20px;
      animation: scrollAnim 1.1s infinite;
    }
  }
  
  .arrow {
    width: 6px;
    height: 6px;
    display: block;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    position: relative;
    margin: 0 auto;
    animation: arrowAnim 1.2s infinite;

    &.arrow-01 {
      animation-delay: .2s;
    }
    &.arrow-02 {
      animation-delay: .3s;
    }
    &.arrow-03 {
      animation-delay: .4s;
    }
  }
}

// Mouse wheel animation
@keyframes scrollAnim {
  from { 
    top: 5px;
  }
  to {
    top: 15px;
    opacity: 0;
  }
}

// Arrows animation
@keyframes arrowAnim {
  0% {
    opacity: 0;
  }
  50% { 
    opacity: 1;
  }
  100% { 
    opacity: 0;
  }
}


//
// Hand animation
// --------------------------------------
.hand-icon {
  position: relative;
}

.hand {
  background: #fff;
  width: 5px;
  height: 16px;
  border-radius: 20px;
  position: relative;
  left: -5px;
  margin-bottom: 17px;
  transform: rotate(-20deg);
  animation: handAnim 1.2s infinite;

  // hand
  &:after {
    content: "";
    background: #fff;
    width: 17px;
    height: 18px;
    border-radius: 4px 8px 38px 15px;
    transform: rotate(6deg) skewY(10deg);
    position: absolute;
    top: 13px;
    left: -1px;
  }

  // thumb
  &:before {
    content: "";
    background: #fff;
    width: 6px;
    height: 17px;
    border-radius: 2px 40px 20px 20px;
    position: absolute;
    top: 12px;
    left: -6px;
    transform: rotate(-38deg);
  }
  
  // circle
  .circle {
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -7px;
    opacity: .5;
    animation: circleAnim 1.2s infinite 2.1s;
  }
}

// Hand animation
@keyframes handAnim {
  0% {
    top: 20px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: -20px;
    opacity: 0;
  }
}

// Circle animation
@keyframes circleAnim {
  from { 
    transform: scale(0);
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}



/*
For Clock Figure
*/

.awssld__container figure{
  @media all and (max-width: 530px) {
    &.dial{
      display: none;
    }
  }
}

/*
*
*/
.projectBody{
  padding: 0px 20px;
  margin-top:120px;
  @media all and (max-width: 550px) {
    .lettering.project{
      display: none;
    }
  }

  .container{
    max-width: 100%;
    padding:0%;
    margin: 0%;

    .row{
      margin: 0px;
      padding:10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}