$sizeBigBox:259px;
$sizeWideHieght: 124px;
a {
    &.wide, &.box, &.bigBox, &.wideNonLive {
        text-decoration: none;
        color: #fff;
        -webkit-transition: -webkit-transform 0.1s;
        position: relative;
        // position: absolute;
        overflow: hidden;
        /*&:hover{
            border: 2.5px white solid;
        }*/

        &.false{
            pointer-events: none;
        }


        h2 {
            font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
            font-weight: 500;
            position: absolute;
            color: #fff;
            left: 8px;
            bottom: 0px;
            font-size: 14px;

            &.top {
                position: static;
                font-size: 14px;
            }
        }

        i {
            font-size: 60px;
            text-align: center;
            display: block;
            margin-top: 10px;
        }
    }
}

a.box .hoverElement {
    
    transform: translate(-10px, -10px);
    height: $sizeWideHieght;
    width: $sizeWideHieght;
    position: absolute;

    &.false{
        pointer-events: none;
    }

    &:hover {
        border: 2px white solid;
        background: #ffffff33;
    }
}

a.wideNonLive .hoverElement {
    transform: translate(-10px, -10px);
    height: $sizeWideHieght;
    width: $sizeBigBox;
    position: absolute;

    &:hover {
        border: 2px white solid;
        background: #ffffff3d;
    }
}

div.bigBox .hoverElement {
    z-index:1;
    transform: translate(4px, 0px);
    height: $sizeBigBox;
    width: $sizeBigBox;
    position: absolute;

    &:hover {
        border: 2px white solid;
        background: #ffffff33;
    }
}

div.wide .hoverElement {
    z-index: 1;
    transform: translate(4px, 0px);
    height: $sizeWideHieght;
    width: $sizeBigBox;
    position: absolute;

    &:hover {
        border: 2px white solid;
        background: #ffffff33;
    }
}

@media (min-width: 670px) {

    .Tiles{
        &.Terminal, &.Resume, &.Github, &.Email, &.Twitter, &.LinkedIn{
            padding-top: 5px;
        }
    }

}

.LiveTilesWrapper {

    overflow: hidden;
    // position:absolute;
    perspective: 750px;

    &.bigBox{
        width: $sizeBigBox+8;
        height: $sizeBigBox+5;
    }

    &.wide{
        width: $sizeBigBox+8;
        height: $sizeWideHieght+5;
    }
    
}

.LiveTile {
    position: relative;
    /*perspective: 600px;*/
    transform-style: preserve-3d;
    /*transform: rotateY(180deg);*/
    transition: transform 0.5s ease 0s;
    margin:auto;

    &.bigBox{
        width: $sizeBigBox;
        height: $sizeBigBox;
    }

    &.wide{
        width: $sizeBigBox;
        height: $sizeWideHieght;
    }

}
.liveCube-face {
    width: inherit;
    height: inherit;
    position: absolute;
}

.bigBox{
    &.liveCube-face{
        margin:auto;
        position:absolute;
    }
    
    &.liveCube-face.faceFront {
        transform: translate3d(0, 0, $sizeBigBox/2);
    }
    
    &.liveCube-face.faceTop {
        transform: rotateX(90deg) translate3d(0px, 0px, $sizeBigBox/2);
    }
    
    &.liveCube-face.faceBack {
        transform: rotateX(180deg) translate3d(0px, 0px, $sizeBigBox/2);
    }
    
    &.liveCube-face.faceBottom {
        transform: rotateX(-90deg) translate3d(0px, 0px, $sizeBigBox/2);
    }
    
    &.faceSideLeft {
        width: $sizeBigBox/2;
        height: $sizeBigBox/2;
        transform: rotateY(-90deg) translate3d(0px, 0px, $sizeBigBox/2);
    }
    
    &.faceSideRight {
        width: $sizeBigBox/2;
        height: $sizeBigBox/2;
        background: magenta;
        transform: rotateY(90deg) translate3d(0px, 0px, $sizeBigBox/2);
    } 
}

.wide{
    &.liveCube-face{
        margin:auto;
        position:absolute;
    }
    
    &.liveCube-face.faceFront {
        transform: translate3d(0, 0, $sizeWideHieght/2);
    }
    
    &.liveCube-face.faceTop {
        transform: rotateX(90deg) translate3d(0px, 0px, $sizeWideHieght/2);
    }
    
    &.liveCube-face.faceBack {
        transform: rotateX(180deg) translate3d(0px, 0px, $sizeWideHieght/2);
    }
    
    &.liveCube-face.faceBottom {
        transform: rotateX(-90deg) translate3d(0px, 0px, $sizeWideHieght/2);
    }
    
    &.faceSideLeft {
        width: $sizeWideHieght/2;
        height: $sizeWideHieght/2;
        transform: rotateY(-90deg) translate3d(0px, 0px, $sizeWideHieght/2);
    }
    
    &.faceSideRight {
        width: $sizeWideHieght/2;
        height: $sizeWideHieght/2;
        background: magenta;
        transform: rotateY(90deg) translate3d(0px, 0px, $sizeWideHieght/2);
    } 
}


@keyframes translateGrid{
    0% {
        transform: translate(0%, 0%);
    }

    100% {
        transform: translate(30%, -30%);
    }
}

div.row {
    &.spacer.anime {
        animation: translateGrid 0.5s;
    }
}