body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  color:white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@media (max-width: 1050px) {
    .lettering .lang {
        display:none;
    }

    .lettering hr {
        display:none;
    }
}

/* Animation for startup */
@keyframes fadeOut {
  0% {
    /* opacity:1; */
    /* display: none; */
    /* margin-top: 0vh; */
    
  }
  100% {
    /* opacity:0; */
    /* display: contents; */
    margin-top: 100vh;
    
  }
}

.fade-out {
  animation: fadeOut ease-in 1.315s;
}
/* Animation for startup */


.career, .research, .education{
  /* margin-top: 85%;
  width: 70%; */
  /* background-color: #004a57;
   background-image: url(https://www.transparenttextures.com/patterns/textured-paper.png); */
  position: relative;
  margin: auto;
}
/* @media (max-width: 910px) {
    .career {
        margin-top: 100%;
    }
} */

.projects{
   /* position: relative; */
  /* margin: auto; */
  /*top: 30px;*/
  width: inherit;
  height: inherit;
  overflow-y: scroll;
}

.awesome-slider-others .awssld__content {
    /* background-color: rgb(69, 74, 88); */
}

.awssld__content {
    overflow: scroll;
}

img{
  height: 100%;
  width: 100%;
}


.row_out{
  height: 230px;
  /* width: 100%; */
}

.bar{
  display: block;
  position: relative;
  height: 24px;
  background-color: #ddd;
  width: 100%;
  color: #6a6a6a;
  text-align: center;

  border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.bar i{
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: #ff5f57;
  border-radius: 50%;
  position: absolute;
  top:6px;
  left:10px;
}

.bar i::before{
  content: " ";
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: #ffbd2e;
  border-radius: 50%;
  position: absolute;
  left:16px;
}

.bar i::after{
  
content: " ";
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: #28ca41;
  border-radius: 50%;
  position: absolute;
  left:32px;
}

.gallery_product.filter{
  width:300px;
  display: contents;
  cursor: pointer;
}

.awssld__startUp svg{
  width: 100%;
  height: 100%;
}


.c{width:240px;height:240px;border:3px solid #98bdcd;border-radius:50%;position:absolute;top:50%;left:50%;transform:translate3d(-50%,-50%,-50px);}
.c:after{content:"";position:absolute;left:-10px;top:-10px;width:100%;height:100%;border-radius:50%;border:10px solid #98bdcd;border-top-color:transparent;border-bottom-color:transparent;animation:R 10s infinite linear;}

.c2{width:100%;height:100%;border:1px solid #7a929c;border-radius:50%;position:absolute;top:0;left:0;padding:1px;animation:L 80s infinite linear;box-sizing:border-box;}

.c3{width:33%;height:33%;border:2px solid #a05d55;border-radius:50%;transform:translate3d(-50%,-50%,5px);position:absolute;top:50%;left:50%;}
.c3:after{content:"";position:absolute;left:-5px;top:-5px;width:100%;height:100%;border-radius:50%;border:5px solid #a05d55;border-top-color:transparent;border-bottom-color:transparent;animation:L 5s infinite linear;}

.c4{width:75px;height:75px;transform:translate3d(-50%,-50%,0px);position:absolute;left:50%;top:50%;}
.c4:after{content:"";width:100%;height:100%;position:absolute;left:-3px;top:-3px;border-radius:50%;border:3px solid #98bdcd;border-left-color:transparent;animation:R 5s infinite linear;}

.c5{width:77px;height:77px;transform:translate3d(-50%,-50%,65px);position:absolute;left:50%;top:50%;border-radius:50%;}
.c5:after{content:"";width:100%;height:100%;position:absolute;left:-1px;top:-1px;border:1px solid #7a929c;border-right-color:transparent;border-bottom-color:transparent;border-radius:50%;animation:L 5s infinite linear;}

.c6{width:55px;height:55px;transform:translate3d(-50%,-50%,61px);position:absolute;left:50%;top:50%;border-radius:50%;}
.c6:after{content:"";width:100%;height:100%;position:absolute;left:-1px;top:-1px;border:1px solid #7a929c;border-right-color:transparent;border-top-color:transparent;border-radius:50%;animation:R 10s infinite linear;}

figure i{height:15px;width:1px;background:#98bdcd;position:absolute;left:50%;margin-left:-10px;transform-origin:1000% 1005%;}

figure i:nth-child(1){transform:rotate(-5deg);}
figure i:nth-child(2){transform:rotate(5deg);}
figure i:nth-child(3){transform:rotate(15deg);}
figure i:nth-child(4){transform:rotate(25deg);}
figure i:nth-child(5){transform:rotate(35deg);}
figure i:nth-child(6){transform:rotate(45deg);}
figure i:nth-child(7){transform:rotate(55deg);}
figure i:nth-child(8){transform:rotate(65deg);}
figure i:nth-child(9){transform:rotate(75deg);}
figure i:nth-child(10){transform:rotate(85deg);}
figure i:nth-child(11){transform:rotate(95deg);}
figure i:nth-child(12){transform:rotate(105deg);}
figure i:nth-child(13){transform:rotate(115deg);}
figure i:nth-child(14){transform:rotate(125deg);}
figure i:nth-child(16){transform:rotate(145deg);}
figure i:nth-child(17){transform:rotate(155deg);}
figure i:nth-child(18){transform:rotate(165deg);}
figure i:nth-child(19){transform:rotate(175deg);}
figure i:nth-child(20){transform:rotate(185deg);}
figure i:nth-child(21){transform:rotate(195deg);}
figure i:nth-child(22){transform:rotate(205deg);}
figure i:nth-child(23){transform:rotate(215deg);}
figure i:nth-child(24){transform:rotate(225deg);}
figure i:nth-child(25){transform:rotate(235deg);}
figure i:nth-child(26){transform:rotate(245deg);}
figure i:nth-child(27){transform:rotate(255deg);}
figure i:nth-child(28){transform:rotate(265deg);}
figure i:nth-child(29){transform:rotate(275deg);}
figure i:nth-child(30){transform:rotate(285deg);}
figure i:nth-child(31){transform:rotate(295deg);}
figure i:nth-child(32){transform:rotate(305deg);}
figure i:nth-child(33){transform:rotate(315deg);}
figure i:nth-child(34){transform:rotate(325deg);}
figure i:nth-child(35){transform:rotate(335deg);}
figure i:nth-child(36){transform:rotate(345deg);}
figure i:nth-child(37){transform:rotate(355deg);}
figure i:nth-child(38){transform:rotate(365deg);}

figure {width:305px;height:305px;margin:0;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%) rotateX(-40deg) rotateY(30deg) scale(1.1,1.1);transition:1s;transform-style: preserve-3d;}
figure.activeClass {transform:translate(-50%,-50%) rotateX(0deg) rotateY(0deg) scale(1.1,1.1);}

@keyframes R{
  0%{transform:rotate(20deg);}
  100%{transform:rotate(380deg)}
}
@keyframes L{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(-360deg)}
}