
.quotes {
    position: absolute;
    bottom: 0px;
    left: 0;
    top: 0px;
}

.quotes section.section {
    border-bottom: 1px dashed #ddd;
}

