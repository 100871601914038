@import '../constants.scss';

.#{$root-element} {
  &--foldOutAnimation {
    --fold-out-perspective: 2000px;
    --fold-out-angle: 90deg;

    .#{$root-element} {
      &__container {
        perspective: var(--fold-out-perspective);
      }

      &--exit {
        &.#{$root-element}--moveLeft,
        &.#{$root-element}--moveRight {
          transform-style: preserve-3d;
        }
        &.#{$root-element}--moveLeft {
          transform-origin: 100% 50%;
          animation: foldLeft var(--slider-transition-duration) both
            var(--transition-bezier);
        }
        &.#{$root-element}--moveRight {
          transform-origin: 0% 50%;
          animation: foldRight var(--slider-transition-duration) both
            var(--transition-bezier);
        }
      }
    }
  }
}

@keyframes foldLeft {
  from {
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
  to {
    transform: translate3d(-100%, 0, 0)
      rotateY(calc(var(--fold-out-angle) * -1));
  }
}

@keyframes foldRight {
  from {
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
  to {
    transform: translate3d(100%, 0, 0) rotateY(var(--fold-out-angle));
  }
}
